<template>
  <div class="container">
    <div class="cover"></div>
    <div id="login_show_box" style="opacity: 1; top: 250px;text-align: left;">
      <div>
        <div class="bind_box">
          <div class="logo">
            <img src="@/assets/sig_logo.png" alt="">
          </div>
          <div class="qrcode_box">
            <div class="qr_title">微信扫码换绑</div>
            <div class="qr_img">
              <div v-loading="qr_loading">
                <img v-show="wxcode_url" :src="wxcode_url" alt="">
                <div v-show="wxcode_url" class="qr_logo">
                  <img src="@/assets/qr_logo.png" alt="">
                </div>
              </div>
            </div>
            <div class="qr_msg">微信扫码 <span>关注「筑龙学社」公众号</span> 即可绑定</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/styles/weblogin.css'
import Cookies from 'js-cookie'
import base64 from '@/utils/base64'
import { getChangeWxBindQrcode } from '@/api/login.js'
export default {
  data() {
    return {
      qr_loading: false,
      websock_open: false,
      wxcode_url: '',
      scene_id: '',
      wss_url: ''
    }
  },
  created() {
    this.funcGetwxqrcode()
  },
  mounted() {

  },
  methods: {
    funcGetwxqrcode() {
      this.qr_loading = true
      getChangeWxBindQrcode().then(res => {
        this.qr_loading = false
        if (res.errNo === 0) {
          this.wxcode_url = res.result.url
          this.scene_id = res.result.scene_id

          this.wss_url = 'wss://www.zhulong.com/wss_socket/'
          this.creatSocket()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.qr_loading = false
        console.log(err)
      })
    },
    // ---------- socket 处理 -----------
    creatSocket() {
      this.websock = new WebSocket(this.wss_url)
      this.websock.onopen = this.websocketonopen
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    websocketonopen(res) {
      // 链接socket
      this.websock_open = true
      console.log('连接成功')
      const params = {
        type: 'login',
        uid: this.scene_id
      }
      this.sendToServer(params)
    },
    websocketonmessage(e) {
      // 接收消息
      var data = JSON.parse(e.data)
      if (data.errNo == 0) {
        this.$message({
          message: data.msg,
          type: 'success'
        })
        window.location.href = 'http://openwww.zhulong.com/ucenter/registinfo'
      } else if (data.errNo == 1015 || data.errNo == 1016) {
        this.$message({
          duration: 6000,
          message: data.msg,
          type: 'error'
        })
      }
    },
    websocketonerror(res) {
      console.log('websock 出现错误', res)
      this.websock_open = false
    },
    websocketclose() {
      this.funcGetwxqrcode()
      console.log('关闭了')
    },
    sendToServer(data) {
      // 给服务端发送消息
      var str = JSON.stringify(data)
      this.websock.send(str)
    }
    // ---------- socket 处理结束 -----------
  }
}

</script>
<style lang="scss" scoped>
.bind_box{
  width: 564px;
  height: 424px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 599;
  background-color: #fff;
  margin: -240px -300px;
  padding: 0 36px;
  // font-family: 'PingFangSC';
  font-family: PingFangSC-Medium;
  color: #333;
}
.logo{
  margin-top: 18px;
  margin: 18px 0 0 -10px;
  height: 52px;
  display: flex;
  align-items: center;
}
.qrcode_box{
  margin-top: 12px;
  // background-color: aquamarine;
  .qr_title{
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
  .qr_img{
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin: 16px 0;
    div{
      width: 200px;
      height: 200px;
      // padding: 20px;
      // background-color: aqua;
      position: relative;
    }
    img{
      width: 100%;
      height: 100%;
      background-color: #fff;
    }
    .qr_logo{
      position: absolute;
      width: 44px;
      height: 44px;
      padding: 3px;
      background-color: #fff;
      border-radius: 3px;
      top: calc(50% - 22px);
      left: calc(50% - 22px);
      img{
        width: 42px;
        height: 42px;
        border-radius: 3px;
        border: 1px solid #999;
      }
    }
  }
  .qr_msg{
    font-size: 16px;
    color: #ee2e2e;
    text-align: center;
    font-family: PingFangSC-Regular;
    span{
      font-family: PingFangSC-Medium;
      font-weight: 500;
    }
  }
}
</style>
